import * as Sentry from '@sentry/nextjs';

export function setupSentry() {
  const isDev = process.env.NODE_ENV === 'development';
  const dsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  const disabled = !dsn;

  Sentry.init({
    dsn: isDev || disabled ? null : dsn,
    enableTracing: false,
    autoSessionTracking: false,
    tracePropagationTargets: [],
  });

  Sentry.setTag('service-name', 'help-center-portal');
}
