import 'normalize.css';
import '@heetch/flamingo-react/dist/styles.css';
import '@styles/global.css';
import 'react-datepicker/dist/react-datepicker.css';

import type { AppProps } from 'next/app';
import Script from 'next/script';
import { UmamiConfig } from '@/types/umami';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';
import { localStorageAvailable } from '@/utils/localStorageAvailable';

function parseCookies(cookies: string): Record<string, string> {
  return cookies.split('; ').reduce<Record<string, string>>((acc, cur) => {
    const [k, v] = cur.split('=');
    return { ...acc, [k.toLowerCase()]: v };
  }, {});
}

function MyApp({ Component, pageProps }: AppProps) {
  const { query } = useRouter();
  const [umamiConfig, setUmamiConfig] = useState<UmamiConfig | undefined>(
    undefined,
  );

  const initDataDogRum = (document: { cookie: string }) => {
    const {
      'datadog-rum-app-id': applicationId,
      'datadog-rum-client-token': clientToken,
      'datadog-rum-app-version': version,
      'datadog-rum-samplerate': sampleRate,
      'datadog-rum-premium-samplerate': premiumSampleRate,
      'datadog-rum-tracing-samplerate': tracingSampleRate,
      'enable-datadog-rum': enable,
    } = parseCookies(document.cookie);
    if (enable && applicationId && clientToken) {
      datadogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.com',
        service: 'help-center-portal',
        version,
        env: 'prod',
        sessionSampleRate: sampleRate ? Number(sampleRate) : 100,
        sessionReplaySampleRate: premiumSampleRate
          ? Number(premiumSampleRate)
          : 0,
        traceSampleRate: tracingSampleRate ? Number(tracingSampleRate) : 100,
        useSecureSessionCookie: true,
        allowedTracingUrls: [window.location.origin],
        silentMultipleInit: true,
      });
    }
  };

  useEffect(() => {
    if (
      query.ajs_uid &&
      typeof query.ajs_uid === 'string' &&
      localStorageAvailable()
    ) {
      localStorage.setItem('userUid', query.ajs_uid);
    }
  }, [query.ajs_uid]);

  useEffect(() => {
    const { 'umami-id': id, 'umami-url': url } = parseCookies(document.cookie);
    if (!!id && !!url) {
      setUmamiConfig({ id, url: decodeURIComponent(url) });
    }
  }, []);

  useEffect(() => {
    window._axcb = window._axcb || [];
    window._axcb.push(function (sdk) {
      sdk.on(
        'consent:saved',
        function (payload: { consent: { accept: boolean } }) {
          if (!payload.consent.accept) {
            location.reload();
          }
        },
      );
      sdk.on('cookies:complete', function (choices: Record<string, string>) {
        if (choices?.['Datadog RUM']) {
          initDataDogRum(document);
        }
      });
    });
  }, []);

  const widgetLanguage: Record<string, string> = {
    en: 'en',
    fr: 'fr',
    pt: 'pt',
    nl: 'nl',
  };

  return (
    <>
      {typeof window !== 'undefined' && query && (
        <Script id='axeptio'>
          {`
    window.axeptioSettings = {
      clientId: "6336e4a337d4d12b86ae20a5",
      cookiesVersion: "help center-${
        widgetLanguage[query?.language as keyof typeof widgetLanguage]
      }",
    };

    (function(d, s) {
      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
       e.async = true; e.src = "//static.axept.io/sdk-slim.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");
        `}
        </Script>
      )}

      {umamiConfig && (
        <Script
          async
          defer
          data-website-id={umamiConfig.id}
          src={umamiConfig.url}
        />
      )}
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
